import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isMobileMenu = false;

  constructor(private router: Router) {
    router.events.subscribe(val => {
      this.hideMenu();
    });
  }

  ngOnInit() {
  }

  hideMenu() {
    this.isMobileMenu = false;
  }
  handleMenu() {
    this.isMobileMenu = !this.isMobileMenu;
  }
}
