/// <reference types="@types/googlemaps" />
import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
// import {} from '@types/googlemaps';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // @ViewChild('gmap') gmapElement: any;
  // map: google.maps.Map;

  formSubmitted = false;


  model = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    file: ''
  };

  isEmailError = false;
  isFirstNameError = false;
  isMessageError = false;

  constructor(
  ) { }

  ngOnInit() {
    // const mapProp = {
    //   center: new google.maps.LatLng(23.0346231, 72.4628915),
    //   zoom: 15,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP
    // };
    // this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);


  }

  validateRequest() {
    if (this.model.firstName.length === 0) {
      this.isFirstNameError = true;
      return false;
    } else if (this.model.email.length === 0) {
      this.isFirstNameError = false;
      this.isEmailError = true;
      return false;
    } else if (this.model.message.length === 0) {
      this.isMessageError = true;
      this.isEmailError = false;
      return false;
    } else {
      this.isMessageError = false;
      return true;
    }
  }

  submit() {
    if (this.validateRequest()) {
      this.model = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        file: ''
      };

      this.formSubmitted = true;
      setTimeout(() => {
        this.formSubmitted = false;
      }, 5000);
    }
  }

}
